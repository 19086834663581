import React from "react";
import { AuthConsumer } from "./AuthProvider";
import ManyosSpinner from "../../Components/ManyosSpinner/ManyosSpinner"

export const LogoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return
                <ManyosSpinner />
           
        }}
    </AuthConsumer>
);
