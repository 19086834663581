import React from "react";



import { Row,Col,Button } from 'antd';


import Table from './Table';


import {ExportCSV} from "../ExportFile/ExportToCSV";
import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";

import Placeholder from "../Placeholder/Placeholder";

export default function RequestTable(props) {


    const {datasource,columns,cValue,onClick,configId,expandable,actions,filter,selectable,onChange,hiddenEval,table,...rest}=props



    const [loading,setLoading]=React.useState(false);


    const [exportData,setExportData] = React.useState([]);






    React.useEffect(()=>{

        if (datasource ) {
            setExportData({datasource,columns})
        }

    },[datasource])

    function ExpandedRow(props){


        const {expandConf,val}= props;

        const params={
            params:{
                ...expandConf.dataScript.params,
                currentValue:{value:val}
            },

        }

       // const resultExecuteOnLoad=useQuery({url:`${window._env_.REACT_APP_API_URL}/v1/catalog/requests/${configId}/executeScript/${expandConf.dataScript.id}`,requestOptions:{method:"POST",headers:{"content-type":"application/json"},body:JSON.stringify({...params,requestData:{}})}},[props],expandConf.dataScript.hasOwnProperty("id"))

       /* <Table  dataSource={datasource} columns={columns}
                pagination={false}
        /> */

    }


    const expandedRowRender = (record)=>{


        return <ExpandedRow val={record[expandable.value]} expandConf={expandable}/>


    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {

            onChange(selectedRows);
        },
        selectedRowKeys:cValue&& cValue.map(row=>row.key)
    };




    if (hiddenEval){
        return (
            <Placeholder {...rest}/>
        )
    }else {
        return (

            <div>

                {
                    (columns)
                        ? (
                            <>
                                <Row gutter={[16, 16]} align={"bottom"} justify={"end"}>


                                    <Col xs={24} sm={24} md={24} xl={24}>
                                        {((datasource && datasource && !loading)) &&
                                        <>
                                            <ExportCSV key={"export-" + rest.elPath}
                                                       style={{float: "right", marginBottom: "15px", marginLeft: "10px"}}
                                                       table={table} tabledata={datasource}/>

                                        </>
                                        }

                                    </Col>

                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} xl={24}>
                                        {


                                            (columns && !loading)
                                                ? (

                                                    <>

                                                        <Table key={"table-" + rest.elPath} dataSource={datasource}
                                                               columns={columns}

                                                                onRow={(record,index)=>{

                                                                    return {
                                                                        onClick: event => {
                                                                            onClick(record["_id"]);
                                                                        },
                                                                    } // click row
                                                                }}

                                                               expandable={expandable && {expandedRowRender}}
                                                               actions={actions && actions}
                                                               rowSelection={selectable && {
                                                                   type: selectable,
                                                                   ...rowSelection
                                                               }}
                                                               {...rest}

                                                        />
                                                    </>
                                                )
                                                : (<ManyosSpinner/>)
                                        }
                                    </Col>

                                </Row>
                            </>
                        )
                        : (
                            <Row gutter={20}>
                                <Col span={12} offset={6}>
                                    <div/>
                                </Col>
                            </Row>
                        )
                }
            </div>
        );
    }
}
