import React,{useEffect,useContext,useState} from "react";

import {useHistory, useParams} from "react-router-dom";
import {getTableDefinition,getTableData} from "../../util/redux/asyncActions";
import {useDispatch,connect} from "react-redux";
import {AuthContext} from "../../util/Auth/AuthProvider";
import RequestTable from "../../Components/Table/RequestTable";
import TicketDetails from "../../Components/TicketDetails/TicketDetails";
import ManyosSpinner from "../../Components/ManyosSpinner/ManyosSpinner";
import {checkFieldType} from '../../util/componentUtils'

import { Button, Result,Drawer,Space } from 'antd';

const select = state => {
    let sProps={}

    sProps.tableDefinition=state.request.tableDefinition;
    sProps.tableData=state.request.tableData;
    sProps.loading=state.request.loading;
    return sProps

}
function Console(props){

    const {tableDefinition,loading,tableData}=props;
    const {table} = useParams();
    const dispatch=useDispatch();
    const history = useHistory();

    const userManager = useContext(AuthContext);
    const [columns,setColumns] = useState();


    const openDetails = (id)=>{

            var win = window.open(`/table/${table}/default/${id}`, '_blank');
            win.focus();


    }



    useEffect(()=>{

        dispatch(getTableDefinition({table, history, userManager}))

    },[])
    useEffect(()=>{

        if (tableDefinition){
            document.title=tableDefinition.id
            dispatch(getTableData({table, history, userManager}))

            const visibleFields=tableDefinition.fields.filter(field=> checkFieldType(field.type))
            const cols = visibleFields.map(field=>{
                return {
                    title: field.label || field.id,
                    dataIndex: field.id,
                    key: field.id
                }
            })

            setColumns(cols)

        }


    },[tableDefinition])

    return(

        (loading)
            ? (<ManyosSpinner/>)
            : (
                (columns )
                    ?(
                        <>
                            <h2>
                            {tableDefinition.id}
                            </h2>
                            <RequestTable datasource={tableData} columns={columns} table={table}
                            onClick={openDetails}
                                          actions={[
                                              {
                                                  "type":"url",
                                                  "label":"open",
                                                  "params":{
                                                      "url":"/table/"+table+"/default/${_id}",
                                                      "target":"_blank"
                                                  }
                                              }

                                          ]}
                            ></RequestTable>


                        </>
                    )
                    :(<Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                        extra={<Button type="primary">Back Home</Button>}
                    />)
            )


    )
}

export default connect(select)(Console)
