import React,{useEffect,useContext,useState} from "react";

import ManyosSpinner from "../../Components/ManyosSpinner/ManyosSpinner";
import {resetSave} from "../../util/redux/index";
import { useStore } from 'react-redux'
import { Button,Result,Divider, Input, Select,Col,Row,Typography,Alert,Layout ,Space} from 'antd';
import {useHistory, useParams} from "react-router-dom";
import Widget from "../../Components/Widget/Widget"
import {AuthContext} from "../../util/Auth/AuthProvider";
import {renderWidget} from "../../util/componentUtils"
import {EditOutlined,SaveOutlined,StopOutlined} from "@ant-design/icons"

import JsxParser from 'react-jsx-parser'

import {
    getTableDefinition,
    getTableData,
    getTableEntry,
    getViewDefinition,
    setTableEntry
} from "../../util/redux/asyncActions";
import {setEdit} from "../../util/redux/index";
import {useDispatch,connect} from "react-redux";
import HeaderActions from "./HeaderActions";


const {Text,Title}=Typography;
const {Header,Content} = Layout
const _ = require("lodash");



const select = state => {
    let sProps={}

    sProps.tableDefinition=state.request.tableDefinition;
    sProps.tableEntry=state.request.tableEntry;
    sProps.viewDefinition=state.request.viewDefinition;
    sProps.loading=state.request.loading;
    sProps.edit = state.request.edit;
    sProps.saveLoading = state.request.saveLoading;
    sProps.saveSuccess = state.request.saveSuccess;

    return sProps

}
export const ViewContext = React.createContext({edit:true,tableEntry:{}});
function TicketDetails(props){

    const {tableDefinition,loading,tableEntry,viewDefinition,edit,saveLoading,saveSuccess}=props;

    const {table,view,id} = props;
    const dispatch=useDispatch();
    const history = useHistory();
    const userManager = useContext(AuthContext);
    const [columns,setColumns] = useState();
    const [preparing,setPreparing] = useState(true);

    const [jsxCode,setJsxCode] = useState();
    const [title,setTitle] = useState();


    useEffect(()=>{
        dispatch(getTableDefinition({table,history,userManager}))
    },[])
    useEffect(()=>{
        if(id && tableDefinition){

            dispatch(getTableEntry({table,id,history,userManager}))
            dispatch(getViewDefinition({table,view,history,userManager}))
        }

    },[tableDefinition])

    useEffect(()=>{


        if(id && tableEntry && viewDefinition){


            setPreparing(false);

            setJsxCode(renderWidget(viewDefinition,tableEntry))


        }

    },[tableEntry,id,viewDefinition])




    return (
        (loading || preparing)
            ?(<ManyosSpinner/>)
            :(
                <>
                    {saveSuccess &&
                        <Alert type={"success"} onClose={()=>{dispatch(resetSave())}}>Saved successfully!</Alert>
                    }
                    {
                        <Layout>
                            <Header>
                                <Title level={3} className="tableTitle" >{title}</Title>
                               <HeaderActions
                                    table={table}
                                    id={id}
                               />
                            </Header>

                            <Content key={"table-content"}>
                                <ViewContext.Provider value={{edit,tableEntry,tableDefinition}}>
                                {jsxCode &&  <JsxParser
                                    key={"request-view"}
                                    disableKeyGeneration={false}
                                components={{Button, Input, Select,Row,Col,Text,Typography,Alert,Widget,Divider,Result,Space,Title,React}}
                                bindings={{tableEntry,edit,setTitle}}
                                blacklistedAttrs={[]}
                                jsx={jsxCode}
                                />}
                                </ViewContext.Provider>
                            </Content>

                        </Layout>

                    }




                </>
            )


    )
}

export default connect(select)(React.memo(TicketDetails))
