import React,{useEffect,useContext,useState} from "react";


import { useParams} from "react-router-dom";
import TicketDetails from "../../Components/TicketDetails/TicketDetails"



function Table(props){



    const {table,view,id} = useParams();


    return (
       <TicketDetails
           table={table}
           view={view}
           id={id}
       />


    )
}

export default Table
