import React, {useContext} from 'react';
import {connect, useDispatch, useStore} from "react-redux";
import {Button, Space,Typography} from "antd";
import {EditOutlined, SaveOutlined, StopOutlined} from "@ant-design/icons";
import {setTableEntry} from "../../util/redux/asyncActions";
import {setEdit} from "../../util/redux";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../util/Auth/AuthProvider";


const {Text}= Typography
const select = state=>{

    let sProps={};
    sProps.invalidFields=state.request.invalidFields;
    sProps.edit=state.request.edit;
    sProps.saveLoading=state.request.saveLoading;

    return sProps;
}


function HeaderActions(props){

    const {edit,saveLoading,table,id,invalidFields}=props;
    const history = useHistory();
    const userManager = useContext(AuthContext);
    const store = useStore()
    const dispatch=useDispatch();

    return(
        <Space style={{float:"right"}}>

            {invalidFields.length==1 &&
                (
                    <Text type={"danger"}>
                        {`${invalidFields.length} field is invalid`}
                    </Text>
                )
            }
            {invalidFields.length>1 &&
                (
                    <Text type={"danger"}>
                        {`${invalidFields.length} fields are invalid`}
                    </Text>
                )
            }
            <Button
                loading={saveLoading}
                disabled={edit && invalidFields.length>0}
                icon={(edit)
                    ?(<SaveOutlined />)
                    :(<EditOutlined />) }
                onClick={()=>{
                    if (edit){

                        const changedData = store.getState().request.changedData


                        dispatch(setTableEntry({table,id,userManager,history,data:changedData}))
                    }
                    dispatch(setEdit(!edit))}}>
                {(!edit)?"Edit":"Save"}
            </Button>
            {edit && <Button
                icon={<StopOutlined />}
                onClick={()=>{

                    dispatch(setEdit(!edit))}}>
                Cancel
            </Button>}
        </Space>
    )
}


export default connect(select)(React.memo(HeaderActions))
