import {
    createAsyncThunk
} from "@reduxjs/toolkit";

import {translateQuery,checkFieldType,formattedDate} from '../componentUtils'


export function restApi({ url,requestOptions,history,userManager }){

    return userManager.getUser().then(user=>{
           if(user){
                if (requestOptions.hasOwnProperty("headers")){
                    requestOptions.headers.Authorization =  "Bearer " + user.access_token
                }else{
                    requestOptions.headers={"Authorization":  "Bearer " + user.access_token}
                }
            }


           return fetch(url, requestOptions)
                    .then(async data => {

                            if (data.status && data.status>400){
                                let error = await data.json();

                                history.replace(history.location.pathname, {
                                    errorStatusCode: data.status,
                                    errorDetail: JSON.stringify({url,...error})
                                });
                            }else{

                                return(requestOptions.method==="GET" || requestOptions.method==="PUT")  ?  data.json() : {}


                            }

                        })


                    .then(({  ...apiData}) => {

                        if ( ({...apiData}.hasOwnProperty("error") && {...apiData}.hasOwnProperty("stackTrace"))) {

                            const error = {...apiData};
                            history.replace(history.location.pathname, {
                                errorStatusCode: 500,
                                errorDetail: JSON.stringify({url,...error})
                            });
                           throw Error(JSON.stringify(error))
                        } else {
                            if (Array.isArray(apiData)){
                                return apiData ;
                            }else{

                               return apiData;

                            }


                        }
                    }).catch(error => {throw Error(error)});



})
}

export const setTicketConfig = createAsyncThunk("request/getTicketConfig",  async ({ id, history,userManager },thunkAPI) => {
    const path = `/config/${id}.json`
    const response = await fetch(path, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    }).catch(error=>{

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path,error:""+error})
        });
    }).then(response=>response.json()).catch(error=>{

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path,error:""+error})
        });
    }).then(data=>{
        const ticketConfig = data
        return {ticketConfig}
    });

    return response

});


export const getTableDefinition = createAsyncThunk("request/getTableDefinition",  async ({ table, history,userManager },thunkAPI) => {

    const response = await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/definitions/tables/${table}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history});
    response.fields=[...response.fields,
                        {
                            id:"created",type:"date"
                        },
                        {
                            id:"modified",type:"date"
                        },
    ]
    return response

});
export const getConfigs = createAsyncThunk("request/getConfig",  async ({  history,userManager },thunkAPI) => {
    const path = `/config/meta.json`
    const response = await fetch(path, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    }).catch(error=>{

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path,error})
        });
    }).then(response=>response.json()).catch(error=>{

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path,error})
        });
    }).then(data=>{


        return Object.keys(data[0]).map(c=>({label:c,value:data[0][c].file}))


    }).catch(error=> {

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path, error})
        });
        ;

    })

    return response




});

export const saveTicket = createAsyncThunk("request/saveTicket",  async ({ item,fields,ticketConfig,status, history,userManager },thunkAPI) => {


    const response = await restApi(
        {
            url:`${window._env_.REACT_APP_API_URL}/api/arsys/v1/entry/${ticketConfig.formName}/${item[ticketConfig.requestID]}`,
            requestOptions:{method:"PUT",headers:{"content-type":"application/json","X-Requested-By":"SMILEkanban"},
            body:JSON.stringify(
                {
                    values:{...fields,"Status":status}
                }
            )}
    ,userManager,history});
    const ticket = await restApi(
        {
            url:`${window._env_.REACT_APP_API_URL}/api/arsys/v1/entry/${ticketConfig.formName}/${item[ticketConfig.requestID]}`,
            requestOptions:{method:"GET",headers:{"content-type":"application/json","X-Requested-By":"SMILEkanban"}}

            ,userManager,history});


    // thunkAPI.getState().request.values is immutable object. next line creates a mutable object.
    let tickets=JSON.parse(JSON.stringify(thunkAPI.getState().request.tickets));

    tickets = tickets.entries.map(e=>{
        if (e.values[ticketConfig.requestID] ===item[ticketConfig.requestID]) {
            return {...e,values:ticket.values}
        }
       return e

    } )



    return tickets
});
export const createWorklog = createAsyncThunk("request/createWorklog",  async ({ item,wlFields,worklogConfig, history,userManager },thunkAPI) => {

    const form=worklogConfig.form
    const ticketid=item[worklogConfig.idField]
    const constants=worklogConfig.constants
    const relId=worklogConfig.worklogRelId


    const response = await restApi(
        {
            url:`${window._env_.REACT_APP_API_URL}/api/arsys/v1/entry/${form}`,
            requestOptions:{method:"POST",headers:{"content-type":"application/json","X-Requested-By":"SMILEkanban"},
                body:JSON.stringify(
                    {
                        values:{...wlFields,...constants,[relId]:ticketid}
                    }
                )}
            ,userManager,history});

    let query = encodeURI(`'${relId}'="${ticketid}"`)

    const responseWL = await restApi({url:`${window._env_.REACT_APP_API_URL}/api/arsys/v1/entry/${form}?q=${query}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history});

    return responseWL;

});
export const searchInRemedy = createAsyncThunk("request/searchInRemedy",  async ({ module, config,value, history,userManager }) => {


    const form=config.formName
    if (config.searchFields && Array.isArray(config.searchFields) && config.searchFields.length>0 && value.length > 2){
        let query= ""
        config.searchFields.forEach((field,i)=>{
            if (i==0){
                query=`'${field}' LIKE "%${value}%"`
            }else{
                query=`${query} OR '${field}' LIKE "%${value}%"`
            }
        });


        query=encodeURI(query);

        const response = await restApi({url:`${window._env_.REACT_APP_API_URL}/api/arsys/v1/entry/${form}?q=${query}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history});
        console.log(response);

        const finResult= response.entries.map(ticket => {

           let result={}
            result.value=ticket.values[config.idField];
            Object.keys(config.cardFields).map(field=>{
                result[field]=ticket.values[config.cardFields[field]]
            })

            return result
        })
        console.log(finResult);
        return finResult;

    }else{
        return {};
    }


});
export const getTicketWorklogs = createAsyncThunk("request/getTicketWorklogs",  async ({ item, worklogConfig, history,userManager }) => {




    const form=worklogConfig.form
    const ticketid=item[worklogConfig.idField]
    const relId=worklogConfig.worklogRelId
    let query = encodeURI(`'${relId}'="${ticketid}"`)

    const response = await restApi({url:`${window._env_.REACT_APP_API_URL}/api/arsys/v1/entry/${form}?q=${query}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history});

    return response;
});


export const setQuery = createAsyncThunk("request/getTickets",  async ({ selection, ticketConfig, history,userManager }) => {

    const user = await userManager.getUser()
    let values={
        "user.profile.name":user.profile.name
    }

    let query

    /*let words = ticketConfig.keywords && await Promise.all(Object.keys(ticketConfig.keywords).map(async keyword=>{
        const conf = ticketConfig.keywords[keyword]

        if (conf.table && conf.tableQuery ){
            const translatedQuery=encodeURI(translateQuery(conf.tableQuery,values))
            const keywordResponse=await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/tables/${conf.form}?q=${translatedQuery}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history})
                if (keywordResponse && keywordResponse.entries && Array.isArray(keywordResponse.entries)){

                    return {[keyword]:{values:keywordResponse.entries.map(e=>e.values[conf.tableField]),joinOperator:conf.joinOperator}}
                }



        }else{
           return {
                [keyword]:translateQuery(conf.query,values)
            }

        }



    }))

   words.forEach(w=>{

       values={...values,...w}
   })
    const filterConf=ticketConfig.filter && ticketConfig.filter.find(e=>e.name===selection)


    query = translateQuery(filterConf.query,values)*/



    const apiData = await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/tables/${ticketConfig.tableName}?q=${query}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history});
    const response =Object.keys(apiData).map(key => apiData[key])
    return {query,response};
});


export const getTableData = createAsyncThunk("request/getTableData",  async ({  table, history,userManager },thunkAPI) => {

    const user = await userManager.getUser()
    const currentState=thunkAPI.getState().request



    let query

    /*let words = ticketConfig.keywords && await Promise.all(Object.keys(ticketConfig.keywords).map(async keyword=>{
        const conf = ticketConfig.keywords[keyword]

        if (conf.table && conf.tableQuery ){
            const translatedQuery=encodeURI(translateQuery(conf.tableQuery,values))
            const keywordResponse=await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/tables/${conf.form}?q=${translatedQuery}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history})
                if (keywordResponse && keywordResponse.entries && Array.isArray(keywordResponse.entries)){

                    return {[keyword]:{values:keywordResponse.entries.map(e=>e.values[conf.tableField]),joinOperator:conf.joinOperator}}
                }



        }else{
           return {
                [keyword]:translateQuery(conf.query,values)
            }

        }



    }))

   words.forEach(w=>{

       values={...values,...w}
   })
    const filterConf=ticketConfig.filter && ticketConfig.filter.find(e=>e.name===selection)


    query = translateQuery(filterConf.query,values)*/



    const apiData = await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/tables/${table}?q=${query}`,requestOptions:{method:"GET","content-type":"application/json"},userManager,history});
    let response =Object.keys(apiData).map(key => apiData[key])
    if (currentState.tableDefinition){

        currentState.tableDefinition.fields.forEach(field=>{

            if (field.type == "enum"){
                const mapped=response.map(entry=>{
                    if (entry[field.id] && entry[field.id].value){
                        return {...entry,[field.id]:entry[field.id].value}
                    }
                    return entry
                })
                response=mapped
            }else if(field.type == "boolean"){
                const mapped=response.map(entry=>{
                    if (entry[field.id] ){
                        return {...entry,[field.id]:"Yes"}
                    }else{
                        return {...entry,[field.id]:"No"}
                    }
                    return entry
                })
                response=mapped
            }else if(field.type == "date"){
                const mapped=response.map(entry=>{
                    if (entry[field.id] ){
                        console.log(formattedDate(entry[field.id] ))
                        return {...entry,[field.id]:formattedDate(entry[field.id] )}
                    }
                    return entry
                })
                response=mapped
            }



        })
    }

    return {query,response};
});

export const getTableEntry = createAsyncThunk("request/getTableEntry",  async ({  table,id, history,userManager },thunkAPI) => {



    const apiData = await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/tables/${table}/${id}`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}},userManager,history});

    return apiData;
});

export const setTableEntry = createAsyncThunk("request/getTableEntry",  async ({  table,id,data, history,userManager },thunkAPI) => {



    const apiData = await restApi({url:`${window._env_.REACT_APP_API_URL}/v1/tables/${table}/${id}`,requestOptions:{method:"PUT",headers:{"content-type":"application/json"},body:(JSON.stringify(data))},userManager,history});

    return apiData;
});



export const getWidgetDefinition = createAsyncThunk("request/getWidgetDefinition",  async ({ widget, history,userManager },thunkAPI) => {

    if (widget){
        const path = `/config/widgets/${widget}.js`

        const response = await fetch(path, {
            headers: {
                "Content-Type": "application/javascript",
                Accept: "application/javascript"
            }
        }).catch(error=>{

            history.replace(history.location.pathname, {
                errorStatusCode: 500,
                errorDetail: JSON.stringify({path,error:""+error})
            });
        }).then(response=>response.text()).catch(error=>{

            history.replace(history.location.pathname, {
                errorStatusCode: 500,
                errorDetail: JSON.stringify({path,error:""+error})
            });
        }).then(data=>{


            return {key:widget,definition:data}
        });

        return response
    }else{
        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({error:"widget is undefined"})
        });
    }


});


export const getViewDefinition = createAsyncThunk("request/getViewDefinition",  async ({ table,view, history,userManager },thunkAPI) => {
    const path = `/config/views/${table}.js`
    const response = await fetch(path, {
        headers: {
            "Content-Type": "application/javascript",
            Accept: "application/javascript"
        }
    }).catch(error=>{

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path,error:""+error})
        });
    }).then(response=>response.text()).catch(error=>{

        history.replace(history.location.pathname, {
            errorStatusCode: 500,
            errorDetail: JSON.stringify({path,error:""+error})
        });
    }).then(data=>{
       // const ticketConfig = data

       // const viewDefinition=ticketConfig.find(viewDef=>viewDef.id==view)

       // return viewDefinition
        return data
    });

    return response

});
