import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import {ErrorBoundary} from '../ErrorBoundary/ErrorBoundary'

const PageBuilder = () => {
    const [elements, setElements] = useState([]);

    const handleDrop = (item, monitor) => {
        const { id, left, top } = monitor.getItem();
        const element = { id, left: monitor.getClientOffset().x - left, top: monitor.getClientOffset().y - top };
        setElements([...elements, element]);
    };

    const handleDelete = (id) => {
        const updatedElements = elements.filter((element) => element.id !== id);
        setElements(updatedElements);
    };

    return (
        <div className="page-builder">
            <div className="toolbox">
                <div className="element" id={"abc"} >drag me</div>

            </div>
            <div className="page">
                {elements.map((element) => (
                    <PageElement key={element.id} id={element.id} left={element.left} top={element.top} onDelete={handleDelete} />
                ))}
                <ErrorBoundary>
                <PageDropzone onDrop={handleDrop} />
                </ErrorBoundary>
            </div>
            <HTMLGenerator elements={elements} />
        </div>
    );
};

const PageElement = ({ id, left, top, onDelete }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.ELEMENT,
        item: { id, left, top },
        collect: (monitor) => {
            console.log("drag",monitor.isDragging())
            return {
            isDragging: monitor.isDragging(),
    }},
    }));

    const handleDelete = () => {
        onDelete(id);
    };

    return (
        <div className="page-element" ref={drag} style={{ left, top, opacity: isDragging ? 0.5 : 1 }}>
            <div className="element-actions">
                <button onClick={handleDelete}>Delete</button>
            </div>
        </div>
    );
};

const PageDropzone = ({ onDrop }) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept:ItemTypes.ELEMENT,
        drop: onDrop,
        collect: (monitor) => {
        console.log(monitor,monitor.canDrop())
        return {
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }},
    }));

    return (
        <div className={`page-dropzone ${(isOver && canDrop ) ? 'page-dropzone--active' : ''}`} ref={drop}  />
    );
};
const HTMLGenerator = ({ elements }) => {
    const generateHTML = (elements) => {
        const htmlStrings = elements.map((element) => {
            // generate HTML string based on element data
            return `<div style="position: absolute; left: ${element.left}px; top: ${element.top}px;"></div>`;
        });

        // concatenate HTML strings into a single string
        return htmlStrings.join('\n');
    };

    const htmlCode = generateHTML(elements);

    return (
        <div className="html-generator">
            <h2>Generated HTML Code</h2>
            <textarea readOnly value={htmlCode} />
        </div>
    );
};

export default PageBuilder;
