import React, { useState,useContext,useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Antd from 'antd';
import * as AntdIcons from '@ant-design/icons'


import {useHistory, useParams} from "react-router-dom";
import {AuthContext} from "../../util/Auth/AuthProvider";
import {useDispatch,connect} from "react-redux";

import {renderWidget,checkRegex,usePrevious,getFieldDef} from "../../util/componentUtils"
import JsxParser from 'react-jsx-parser'
import {handleFieldChange,handleValid} from "../../util/redux";
import {ViewContext} from "../TicketDetails/TicketDetails";

const _ = require("lodash");
const {Text,Title}=Antd.Typography;

const select = (state,{ ...rest}) => {
    let sProps={}

    sProps.widgetDefinitions=state.request.widgetDefinitions;
    sProps.loading=state.request.loading;
    sProps.tableEntry=state.request.tableEntry;
    sProps.edit = state.request.edit;

    sProps = {...sProps,...rest}
    return sProps

}



const WidgetContext = React.createContext({valid:true,regex:undefined,regexHint:undefined});



function Widget(props) {

    const {data,id,widget,required,regex,regexHint} = props;


    const parentProps=useContext(WidgetContext);

    const parentValid=parentProps.valid;
    const parentRegex=parentProps.regex;
    const parentRegexHint=parentProps.regexHint;

    const viewProps=useContext(ViewContext);

    const edit=viewProps.edit;
    const tableEntry=viewProps.tableEntry;
    const tableDefinition = viewProps.tableDefinition;



    const [jsxCode,setJsxCode]=useState();
    const [widgetDefinition,setWidgetDefinition]=useState();

    //load widgetDefinition
    useEffect(()=>{
        if (widget) {
            const path = `/config/widgets/${widget}.js`

             fetch(path, {
                headers: {
                    "Content-Type": "application/javascript",
                    Accept: "application/javascript"
                }
            }).then(response => response.text()).catch(error => {
                 console.error(error)
                setJsxCode("<Antd.Alert message={'Oooops! An error occured: '+JSON.stringify({path,error})} type=\"error\" />")
            }).then(code => {
                 setJsxCode(renderWidget(code,state.localData))
                 setWidgetDefinition(code)
            }).catch(error=>{
                console.error(error)
                setJsxCode("<Antd.Alert message={'Oooops! An error occured: '+JSON.stringify({path,error})} type=\"error\" />")
             });


        }
    },[widget])

    const [state, setState] = useState(()=>{

        if (data && data.dataBinding){
            const text= _.get(tableEntry,data.dataBinding)
            return{ "jsxCode":undefined,
                "initDone":false,
                localData:{...data,text}}
        }
        return{ "jsxCode":undefined,
            "initDone":false,
            localData:{...data}}


    });


    const [valid,setValid] = useState(()=>{

        if (data && data.dataBinding){
            return checkRegex(_.get(tableEntry,data.dataBinding),regex,required)
        }else{
            return true
        }


    })


    const dispatch=useDispatch();
    const history = useHistory();
    const userManager = useContext(AuthContext);
    useEffect(()=>{

            dispatch(handleValid({id,valid}))
            //setJsxCode(renderWidget(widgetDefinition,state.localData))


    },[
        valid
    ])










    useEffect( ()=>{

        if (data && data.dataBinding){
            let text=_.get(tableEntry,data.dataBinding)




            const fieldDef = getFieldDef(data.dataBinding,tableDefinition);
            let options=[]
            if (fieldDef && fieldDef.type==="enum"){
                let id;
                options=fieldDef.values.map(e=>({value:e.id,label:e.label}));
                if (text){
                    id = text.id
                }


                setState({...state,localData:{text:text&&text.value,id,...data},options})
            }else{
                setState({...state,localData:{text,...data},options})
            }


        }




    },[data])












    const handleChange=(e)=>{

        let  val;
        if (e.target){
            val = e.target.value

        }else{
            val = e

        }
        const fieldDef = getFieldDef(data.dataBinding,tableDefinition);

        if (fieldDef && fieldDef.type==="enum"){
            if (val && typeof val === "object"){
                val = {id:val.value,value:val.label}
            }

        }


        if (required || regex){
            let check=checkRegex(val,regex,required)
            setValid(checkRegex(val,regex,required))

            if (check){
                if (data && data.dataBinding){
                    dispatch(handleFieldChange({id:data.dataBinding,val}))
                }

            }



        }else {
            if (data && data.dataBinding){

                dispatch(handleFieldChange({id:data.dataBinding,val}))
            }

        }
    }



    return (

        (jsxCode)
            ?(<WidgetContext.Provider value={{valid,regex,regexHint}}>
                        <JsxParser

                            components={{...Antd,...AntdIcons,Text,Title,React,Widget}}
                            disableKeyGeneration={false}
                            bindings={{valid,options:state.options,value:state.localData.text,...state.localData,parentValid,tableEntry,edit,parentRegexHint,parentRegex,...props,handleChange,runScript:async (id)=>{

                                    const weatherResponse = await fetch('https://api.openweathermap.org/data/2.5/weather?q=Münster&appid=bab78e38577c73eeb20ca872998a43dd&lang=de');
                                    if (weatherResponse) {
                                        let requestData = await weatherResponse.json()
                                        const wetter = 'Das akutelle Wetter in Münster ist: ' + requestData.weather[0].description;

                                        setState({...state,localData:{...state.localData,"text":wetter}})

                                    }
                                }
                            }}
                            blacklistedAttrs={[]}
                            jsx={jsxCode}
                        />
            </WidgetContext.Provider>)
            :null

    );
}

Widget.propTypes = {
    data: PropTypes.object.isRequired,
    widget: PropTypes.string.isRequired,

};

export default connect(select)(React.memo(Widget))
