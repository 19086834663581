import React from 'react'
import { Button, Input, Select,Col,Row,Typography,Alert } from 'antd';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false,error:"" };

    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true,error };
       
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Alert message={`Something went wrong. ${this.state.error}`} type={"error"}></Alert>;
        }

        return this.props.children;
    }
}
