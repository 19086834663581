import React from "react";
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {sorter} from "../../util/sorter";
import {Action} from "./actions";


export default class MyTable extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
    };
    mySorter=sorter;
    myAction=Action;

    getColumnSearchProps = (dataIndex,columns) => {


        const colDef=columns.find(col=>col.dataIndex===dataIndex);
        if (colDef && colDef.filters){
            return ({

                onFilter: (value, record) =>
                    record[dataIndex]
                        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                        : '',


                render: text =>
                    this.state.searchedColumn === dataIndex ? (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[this.state.searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        text
                    ),
            });
        }else{
            return ({
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={node => {
                                this.searchInput = node;
                            }}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>

                        </Space>
                    </div>
                ),
                filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                onFilter: (value, record) =>
                    record[dataIndex]
                        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                        : '',
                onFilterDropdownVisibleChange: visible => {
                    if (visible) {

                        setTimeout(() => this.searchInput.select(), 100);
                    }
                },

                render: text =>
                    this.state.searchedColumn === dataIndex ? (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[this.state.searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        text
                    ),
            });
        }


    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });

    };
    handleChange = (pagination, filters, sorter , extra) => {

        if (this.props.hasOwnProperty("onChange")){

            this.props.onChange(extra.currentDataSource);
        }

    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };



    render() {


        const {columns,sorter,actions,dataSource,onChange,...rest}=this.props;

        let tcolumns = columns.map(c => {
            const {sorter,dataIndex,key,...restCol} = c;
            let tmpObj={...restCol,dataIndex};
            if (key) {
                tmpObj = {...tmpObj, ...this.getColumnSearchProps(c.key,columns)}
            }

            if (this.props.sorter){
                const {compare,...otherSorterProps}=sorter;
                if (compare){

                    tmpObj = {...tmpObj, sorter: {
                            compare: (rowA, rowB) => this.mySorter[compare](rowA[dataIndex], rowB[dataIndex]),
                            ...otherSorterProps,
                        }}
                }else{

                    tmpObj = {...tmpObj, sorter: {
                            compare: (rowA, rowB) => this.mySorter["DEFAULT"](rowA[dataIndex], rowB[dataIndex]),
                            ...otherSorterProps,
                        }}
                }

            }else{
                tmpObj = {...tmpObj, sorter: {
                        compare: (rowA, rowB) => this.mySorter["DEFAULT"](rowA[dataIndex], rowB[dataIndex]),
                        multiple:1,
                    }}
            }

            return tmpObj;
        })


        if (dataSource && dataSource.find(row=>row.hasOwnProperty("dynActions"))){
            tcolumns=[...tcolumns,

                {
                    title: 'Action',
                    key: 'action',
                    onCell: (record,i) => this.myAction(actions,record)
                }

            ]
        }
        else if (actions ){
            tcolumns=[...tcolumns,

                {
                    title: 'Action',
                    key: 'action',
                    onCell: (record,i) => this.myAction(actions,record)
                }

            ]
        }

    
        return <Table columns={tcolumns} dataSource={this.props.dataSource} onChange={this.handleChange} {...rest} />;
    }
}


