import {
    configureStore,
    getDefaultMiddleware,
    createSlice,

} from "@reduxjs/toolkit";



import {setQuery,setTableEntry, setTicketConfig,saveTicket, createWorklog,getTicketWorklogs,getConfigs,searchInRemedy,getTableData,getTableDefinition,getTableEntry,getViewDefinition,getWidgetDefinition} from "./asyncActions";
import _ from "lodash";

const middleware = [
    ...getDefaultMiddleware(),
    /*YOUR CUSTOM MIDDLEWARES HERE*/
];




const kanbanState = {
    loading:true,
    error:undefined,
    accessToken:undefined,
    query:"1=2",
    tickets:[],
    worklogs:[],
    configs:[],
    searchResults:[],
    searching:false,
    edit:false,
    tableData:undefined,
    tableDefinition:undefined,
    tableEntry:undefined,
    viewDefinition:undefined,
    widgetDefinitions:{},
    saveLoading:false,
    saveSuccess:false,
    invalidFields:[],
    changedData:{}
};



const requestSlice = createSlice({
    name: "request",
    initialState: kanbanState,
    reducers: {

        resetState:(state)=> {
            return state = {
                loading: true,
                error: undefined,
                accessToken: undefined,
                query: "1=2",
                tickets: [],
                worklogs: [],
                configs: [],
                searchResults: [],
                searching: false,
                tableData: undefined,
                tableDefinition: undefined,
                tableEntry: undefined,
                viewDefinition: undefined,
                widgetDefinitions: {},
                edit:false,
                saveLoading:false,
                saveSuccess:false,
                invalidFields:[],
                changedData:{}
            }

        },

        handleValid:(state,action)=>{
            const {id,valid}=action.payload
            if (id){
                if (valid){
                    state.invalidFields=state.invalidFields.filter(f=>f!==id)
                }else{
                    state.invalidFields=_.uniq([...state.invalidFields,id])
                }

            }

            return state
        },
        handleFieldChange:(state,action)=>{

            const {id,val}=action.payload
            state.changedData[id]=val
            console.debug("changed entry: "+id + " to value: ",val)
            return state
        },
        resetSave:(state)=> {


                state.saveSuccess=false;

           return state

        },

        setCodeView:(state,action)=> {

            if (action.payload.hasOwnProperty("codeView")){
                state.codeView = action.payload.codeView;
            }
            return state;

        },
        setEdit:(state,action)=> {

            state.edit = action.payload;

            return state;

        },


    },
    extraReducers:{
        [setQuery.pending]: state => {
            state.loading = true;
        },
        [setQuery.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [setQuery.fulfilled]: (state, action) => {
            state.loading = false;
            state.query = action.payload.query;
            state.tickets = action.payload.response;
        },
        [getTableEntry.pending]: state => {
            state.loading = true;
        },
        [getTableEntry.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getTableEntry.fulfilled]: (state, action) => {
            state.loading = false;
            state.tableEntry = action.payload;
        },
        [setTableEntry.pending]: state => {
            state.saveLoading = true;
        },
        [setTableEntry.rejected]: (state, action) => {
            state.saveLoading = false;

            state.error = action.error.message;
        },
        [setTableEntry.fulfilled]: (state, action) => {
            state.saveLoading = false;
            state.tableEntry = action.payload;
        },
        [getViewDefinition.pending]: state => {
            state.loading = true;
        },
        [getViewDefinition.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getViewDefinition.fulfilled]: (state, action) => {
            state.loading = false;
            state.viewDefinition = action.payload;
        },
        [getWidgetDefinition.pending]: state => {
            state.loading = true;
        },
        [getWidgetDefinition.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getWidgetDefinition.fulfilled]: (state, action) => {
            state.loading = false;
            state.widgetDefinitions = {[action.payload.key]:action.payload.definition,...state.widgetDefinitions};

        },
        [getTableData.pending]: state => {
            state.loading = true;
        },
        [getTableData.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getTableData.fulfilled]: (state, action) => {
            state.loading = false;
            state.tableData = action.payload.response;
        },
        [getTableDefinition.pending]: state => {
            state.loading = true;
        },
        [getTableDefinition.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getTableDefinition.fulfilled]: (state, action) => {
            state.loading = false;
            state.tableDefinition = action.payload;
        },
        [saveTicket.pending]: state => {
            state.loading = true;
        },
        [saveTicket.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [saveTicket.fulfilled]: (state, action) => {
            state.loading = false;

            state.tickets.entries = action.payload;
        },
        [createWorklog.pending]: state => {
            state.wlLoading = true;
        },
        [createWorklog.rejected]: (state, action) => {
            state.wlLoading = false;

            state.error = action.error.message;
        },
        [createWorklog.fulfilled]: (state, action) => {
            state.wlLoading = false;
            state.worklogs=action.payload.entries

        },
        [getTicketWorklogs.pending]: state => {
            state.wlLoading = true;
        },
        [getTicketWorklogs.rejected]: (state, action) => {
            state.wlLoading = false;

            state.error = action.error.message;
        },
        [getTicketWorklogs.fulfilled]: (state, action) => {
            state.wlLoading = false;
            state.worklogs=action.payload.entries


        },
        [setTicketConfig.pending]: state => {
            state.loading = true;
        },
        [setTicketConfig.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [setTicketConfig.fulfilled]: (state, action) => {
            state.loading = false;
            state.saveSuccess = true;
            state.ticketConfig = action.payload && action.payload.ticketConfig;

        },
        [getConfigs.pending]: state => {
            state.loading = true;
        },
        [getConfigs.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getConfigs.fulfilled]: (state, action) => {
            state.loading = false;
            state.configs = action.payload;
        },
        [searchInRemedy.pending]: state => {
            state.searching = true;
        },
        [searchInRemedy.rejected]: (state, action) => {
            state.searching = false;

            state.error = action.error.message;
        },
        [searchInRemedy.fulfilled]: (state, action) => {
            state.searching = false;
            state.searchResults = action.payload;
        }

    }
});

export const { resetState,setCodeView,setEdit,resetSave,handleValid,handleFieldChange} = requestSlice.actions;

const requestReducer = requestSlice.reducer;

export default configureStore({
    reducer: {
        request: requestReducer,
    },
    middleware,
});



