import moment from "moment";
import DOMPurify from 'dompurify';
import React from "react"

const Mustache =require('mustache');
const esprima = require('esprima')

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export const translateQuery=(query,allFormValues)=>{
    let re=/\$\{.*?\}/g
    const matches = query.match(re)

    if (matches && matches.length > 0){
        matches.forEach(e=>{

            const field=e.substring(2, e.length-1)
            const nre = new RegExp(escapeRegExp(e));

            if (typeof allFormValues[field] === "string"){
                query=query.replace(nre,allFormValues[field])
            }else{
                if(allFormValues[field] && Array.isArray(allFormValues[field].values) && allFormValues[field].joinOperator){
                    const oQuery=query;
                    allFormValues[field].values.forEach((e,i)=>{

                        if (i===0){
                            query=oQuery.replace(nre,e)
                        }else{
                            query = query + ` ${allFormValues[field].joinOperator} ${oQuery.replace(nre,e)}`
                        }
                    })
                }
            }





        })
    }
    return query

}

export const checkFieldType=(type)=>{
     const allowed=["string","boolean","enum","integer","date"]
     return allowed.indexOf(type)>=0
 }

 export const formattedDate=(date)=>{

    return  moment(date).format(window._env_.REACT_APP_DATE_FORMAT)
 }

export  function checkRegex(value,regex,required) {


    if (required && (value==="" || value===undefined)){

        return false
    }else if (regex && value){
        const val=value;
        const re=new RegExp(regex,"gm");
        return re.test(val);

    } else if ((value===undefined || value.length==0) && required){

        return false;
    }
    else{

        return true;
    }
}

export function usePrevious(value) {
    let ref = React.useRef();
    React.useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export const getFieldDef=(binding,tableDefinition)=>{
    if(binding){
        //TODO: better Idea to find fields in tableDefinitions
        const strings=binding.split(".");
        const fieldName=strings[strings.length-1];
        const fieldDef = tableDefinition.fields.find(field=>field.id===fieldName);

        return fieldDef
    }else{
        return
    }
}
export const renderWidget=(code,data)=>{




    const sanitizedData = DOMPurify.sanitize(JSON.stringify(data));


    const replacedData = Mustache.render(code, data).replace(/\n/g,"").replace(/\r/g,"");


    try{
        const parsedCode = esprima.parse(replacedData, { jsx: true });

    }catch(e) {
        const error = {...e}
        console.error(error)
        return "<Alert message={'Oooops!: " + error.description + "'} type=\"error\" />;"
    }
    return replacedData


}


