import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select,Col,Row,Typography } from 'antd';
import DOMPurify from 'dompurify';
import JsxParser from 'react-jsx-parser'
import MonacoEditor from 'react-monaco-editor';
const Mustache =require('mustache');
const esprima = require('esprima')
const {Text}=Typography;

function WidgetBuilder({ data,name,path }) {
    const [jsxCode, setJsxCode] = useState();
    const [renderCode,setRenderCode] = useState();

    const handleCodeChange = (value) => {
        const userCode = value;
        setJsxCode(userCode);
    };

    const renderWidget=(code)=>{

        const sanitizedData = DOMPurify.sanitize(JSON.stringify(data));

        const replacedData = Mustache.render(code, data);

        console.log(replacedData,data)
        const parsedCode = esprima.parse(replacedData, { jsx: true });
        console.log('Code is valid:', parsedCode);

        setRenderCode(replacedData)
    }




    const editorOptions = {
        selectOnLineNumbers: true,
        renderIndentGuides: true,
        autoIndent: true,
        minimap: {
            enabled: false,
        },
    };

    return (
        <div>
            <h1>Widget Builder</h1>
            <p>Input your JSX code below:</p>
            <MonacoEditor
                language="javascript"
                theme="vs-light"
                value={jsxCode}
                options={editorOptions}
                onChange={handleCodeChange}
                height="400"
                width="100%"
            />
            <hr />
            <Button
                onClick={()=>renderWidget(jsxCode)}
            >Preview</Button>
            <h2>Preview:</h2>
            <JsxParser
                components={{Button, Input, Select,Row,Col,Text,Typography}}
                jsx={renderCode}
            />
        </div>
    );
}

WidgetBuilder.propTypes = {
    data: PropTypes.object.isRequired,
};

export default WidgetBuilder;
