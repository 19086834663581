import React from 'react';
import WidgetBuilder from '../../Components/WidgetBuilder/WidgetBuilder'

const data = {
    summary: 'Test Summary',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    priority: 'High',
    status: 'Assigned',
    customer: {
        firstName: 'Alex',
        lastName: 'Stern',
        company: {
            id: '123',
            name: 'Calbro',
            location: {
                country: 'Germany',
                city: 'Munich',
                address: 'Street 1',
                zipcode: '83144',
            },
        },
    },
};

function App() {
    return (
        <div>
            <h1>Widgets</h1>
            <WidgetBuilder data={data} />
        </div>
    );
}

export default App;
