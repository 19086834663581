import React from 'react';
import {Select,Typography,Col} from 'antd';
import useQuery from "../../util/useQuery"

import {QuestionCircleOutlined} from '@ant-design/icons'

const {Option}=Select
const {Text} = Typography;

const SelectField = ( props)=>{

    const {options,id,onChange,required,valid,label,cValue}=props


    const [value,setValue]=React.useState();

    React.useEffect(()=>{
        if (cValue){
            setValue(
                {
                    value:cValue.id,
                    label:cValue.value

                }
            )
        }
    },[cValue])


    return (
        <div>
            <br/>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Text type={(!valid && required)?("danger"):("")} strong>{(!valid && required)?("* " + label):(label)}</Text>

            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                    style={{width:"100%"}}
                    key={id}
                    value={value}
                    showSearch
                    filterOption={(input,option)=>(option.label.toLowerCase().indexOf(input.toLowerCase())>=0)}
                    onChange={(val,e)=>{



                        onChange(val)




                    }}
                    options={options}
                />
            </Col>
        </div>

    )
}

export default SelectField
