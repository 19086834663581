import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Console from './Views/Console/Console'
import Table from './Views/Table/Table'
import Widgets from './Views/Widgets/Widgets'
import PageBuilder from './Components/PageBuilder/PageBuilder'
import 'antd/dist/antd.css';
import {Provider} from "react-redux";
import store from "./util/redux/index";
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import { Callback } from "./util/Auth/Callback";
import { Logout } from "./util/Auth/Logout";
import { LogoutCallback } from "./util/Auth/LogoutCallback";
import { PrivateRoute } from "./util/Auth/PrivateRoute";
import { SilentRenew } from "./util/Auth/SilentRenew";
import ErrorHandler from "./util/ErrorHandler"
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


import {AuthProvider} from "./util/Auth/AuthProvider";
let link = document.createElement('link');

// set the attributes for link element
link.rel = 'stylesheet';

link.type = 'text/css';

link.href = '/customer/customer.css';

// Get HTML head element to append
// link element to it
document.getElementsByTagName('HEAD')[0].appendChild(link);

ReactDOM.render(

  <React.StrictMode>
      <Provider store={store}>
          <AuthProvider>
              <DndProvider backend={HTML5Backend}>
          <Router>
              <ErrorHandler>
              <Switch>

                  <Route exact={true} path="/signin-oidc" component={Callback} />
                  <Route exact={true} path="/logout" component={Logout} />
                  <Route exact={true} path="/logout/callback" component={LogoutCallback} />
                  <Route exact={true} path="/silentrenew" component={SilentRenew} />
                  <PrivateRoute path="/table/:table/:view/:id"  component={ Table } />
                  <PrivateRoute path="/console/:table"  component={ Console } />
                  <PrivateRoute path="/kanban/:id"  component={ App } />
                  <PrivateRoute path="/widgetBuilder"  component={ Widgets } />
                  <PrivateRoute path="/pageBuilder"  component={ PageBuilder } />
                  <Redirect from="/console" to="/console/incident"/>
                  <Redirect from="/" to="/console/incident"/>

              </Switch>
              </ErrorHandler>
          </Router>
              </DndProvider>
          </AuthProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
