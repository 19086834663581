import React from "react";
import { AuthConsumer } from "./AuthProvider";
import ManyosSpinner from "../../Components/ManyosSpinner/ManyosSpinner"

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {



            try{
                signinRedirectCallback();
            }catch(e){
                console.log(e);
            }

            return <div className="loginSpin">
                <ManyosSpinner />
            </div>;
        }}
    </AuthConsumer>
);
