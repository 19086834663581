import React from "react";
import { AuthConsumer } from "./AuthProvider";
import ManyosSpinner from "../../Components/ManyosSpinner/ManyosSpinner"
export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <ManyosSpinner/>
        }}
    </AuthConsumer>
);
